import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Highlight: Added import
import "./Interface.css";
import folderIcon from "../../assets/mac-folder.png";
import blurIcon from "../../assets/blur.png";
import logoSrc from "../../assets/CNCTNoutline.png";
import LoadingAnimation from "../LoadingAnimation";

const Interfaces = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [folders, setFolders] = useState([
    {
      id: 1,
      label: "AiMR + Apple AirPod Max",
      x: 45,
      y: 180,
      icon: folderIcon,
      video: "/videos/Apple-AR.mp4",
    },
    {
      id: 2,
      label: "AiMR + FiguresOfSpeech MCA Chicago",
      x: 20,
      y: 300,
      icon: folderIcon,
      video: "/videos/virgilAR.mp4",
    },
    {
      id: 3,
      label: "Syn",
      x: 250,
      y: 380,
      icon: blurIcon,
      video: null, // No video for this folder as it opens a new page
    },
    {
      id: 4,
      label: "Laces.Ai w/ Ebay",
      x: 50,
      y: 510,
      icon: folderIcon,
      video: "/videos/lacesai.mp4",
    },
    {
      id: 5,
      label: "AiMR + PSA",
      x: 150,
      y: 280,
      icon: folderIcon,
      video: "/videos/PSA-AR.mp4",
    },
    {
      id: 6,
      label: "PSA.3D",
      x: 250,
      y: 180,
      icon: folderIcon,
      video: "/videos/PSA3Dglasses.mp4",
    },
    {
      id: 7,
      label: "Voice.AI Metropolitan Library",
      x: 145,
      y: 395,
      icon: folderIcon,
      video: "/videos/voiceai.mp4",
    },
    {
      id: 8,
      label: "Voice.AI Joe Rogan in Spanish",
      x: 200,
      y: 510,
      icon: folderIcon,
      video: "/videos/JREinSpanish.mp4",
    }
  ]);

  const [activePopup, setActivePopup] = useState(null);
  const [videoError, setVideoError] = useState(null);
  const dragItem = useRef(null);
  const dragStartPos = useRef({ x: 0, y: 0 });
  const clickTimer = useRef(null);
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const carouselItems = [
    "Interface Technology",
    "To connect: hi@cnctn-dots.com",
    "For more details chat with Syn✨",
  ];

  const renderCarousel = () => {
    // Create 10 copies of the items to ensure coverage on wide screens
    const repeatedItems = [...Array(10)].flatMap(() => carouselItems);

    return (
      <div className="carousel-container">
        <div className="carousel-track">
          {repeatedItems.map((item, index) => (
            <React.Fragment key={index}>
              <span className="carousel-item">{item}</span>
              {index < repeatedItems.length - 1 && (
                <span className="carousel-dot">●</span>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

  // Reset videoError when activePopup changes
  useEffect(() => {
    setVideoError(null);
  }, [activePopup]);

  // loading animation
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3300); // 3.5 seconds

    return () => clearTimeout(timer);
  }, []);

  // drag and drop logic
  const handleStart = (e, id) => {
    e.preventDefault();
    const clientX = e.type === "mousedown" ? e.clientX : e.touches[0].clientX;
    const clientY = e.type === "mousedown" ? e.clientY : e.touches[0].clientY;

    dragItem.current = id;
    dragStartPos.current = { x: clientX, y: clientY };

    clickTimer.current = setTimeout(() => {
      if (e.type === "mousedown") {
        window.addEventListener("mousemove", handleMove);
        window.addEventListener("mouseup", handleEnd);
      } else {
        window.addEventListener("touchmove", handleMove, { passive: false });
        window.addEventListener("touchend", handleEnd);
      }
    }, 200);

    if (e.type === "mousedown") {
      window.addEventListener("mouseup", handleClick);
    } else {
      window.addEventListener("touchend", handleClick);
    }
  };

  const handleMove = (e) => {
    e.preventDefault();
    clearTimeout(clickTimer.current);
    if (dragItem.current !== null) {
      const clientX = e.type === "mousemove" ? e.clientX : e.touches[0].clientX;
      const clientY = e.type === "mousemove" ? e.clientY : e.touches[0].clientY;

      const dx = clientX - dragStartPos.current.x;
      const dy = clientY - dragStartPos.current.y;

      setFolders((prevFolders) =>
        prevFolders.map((folder) =>
          folder.id === dragItem.current
            ? { ...folder, x: folder.x + dx, y: folder.y + dy }
            : folder
        )
      );

      dragStartPos.current = { x: clientX, y: clientY };
    }
  };

  const handleEnd = () => {
    dragItem.current = null;
    window.removeEventListener("mousemove", handleMove);
    window.removeEventListener("mouseup", handleEnd);
    window.removeEventListener("touchmove", handleMove);
    window.removeEventListener("touchend", handleEnd);
  };

  const handleClick = (e) => {
    e.preventDefault();
    clearTimeout(clickTimer.current);
    window.removeEventListener("mouseup", handleClick);
    window.removeEventListener("touchend", handleClick);

    if (!dragItem.current) return;

    const folder = folders.find((f) => f.id === dragItem.current);
    if (folder.id === 3) {
      // For Syn folder, add logic to open new page here
      navigate("/Syn");
    } else if (folder.video) {
      setActivePopup(folder);
    }
    dragItem.current = null;
  };

  const handleVideoError = (e) => {
    console.error("Video error:", e);
    setVideoError(
      `Failed to load video: ${
        e.target.error?.message || "Unknown error"
      }. Please check the file path and format.`
    );
  };

  return (
    <div className="interface-container">
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <>
          {renderCarousel()}
          {folders.map((folder) => (
            <div
              key={folder.id}
              className={`folder-icon folder-${folder.id}`}
              style={{
                left: `${folder.x}px`,
                top: `${folder.y}px`,
                position: "absolute",
              }}
              onMouseDown={(e) => handleStart(e, folder.id)}
              onTouchStart={(e) => handleStart(e, folder.id)}
            >
              <img
                src={folder.icon}
                alt={`Folder ${folder.label}`}
                draggable="false"
              />
              <div className="folder-label">{folder.label}</div>
            </div>
          ))}
          {activePopup && (
            <div className="popup-overlay">
              <div className="popup-container">
                <div className="popup-header">
                  <h2 className="popup-title">{activePopup.label}</h2>
                  <button
                    className="close-button"
                    onClick={() => setActivePopup(null)}
                  >
                    ×
                  </button>
                </div>
                <div className="popup-video-container">
                  {videoError ? (
                    <div className="video-error">{videoError}</div>
                  ) : (
                    <video
                      key={activePopup.video}
                      ref={videoRef}
                      autoPlay
                      loop
                      playsInline
                      preload="auto"
                      className="popup-video"
                      onError={handleVideoError}
                    >
                      <source src={activePopup.video} type="video/mp4" />
                      Your browser does not support the video tag or the file
                      format of this video.
                    </video>
                  )}
                </div>
              </div>
            </div>
          )}
          <img
            src={logoSrc}
            alt="CNCTN Logo"
            className="bottom-logo"
            preload="auto"
          />
        </>
      )}
    </div>
  );
};

export default Interfaces;
